import React, { useContext, useState } from "react";
import {
  AuthenticationContext,
  EverButton,
  EverSelector,
  PageContainer,
} from "elements.ui";
import {
  Box,
  Card,
  CardContent,
  Grid,
  LinearProgress,
  TextField,
  Typography,
} from "@material-ui/core";
import { Check } from "@material-ui/icons";
import { PolicyImportService } from "../Infrastructure/PolicyImportService";
import { PolicyImportFullContainerRequestDTO } from "../Types/PolicyImportFullContainerRequestDTO";
import { EversureProducts } from "../Types/EversureProducts";
import { getStorageAccountLink } from "../Types/StorageAccountLink";

const breadcrumbs = [
  { label: "Home", link: "/", absolute: true },
  { label: "Bulk Policy Import", isActive: true },
];

const defaultRequest: PolicyImportFullContainerRequestDTO = {
  ContainerName: "policy-import",
  Folder: "CaseImportFiles",
  ProductId: "",
  Importer: "Schemeserve",
  BatchSize: 50,
};

const BulkPolicyImport = () => {
  const [fullContainerRequest, setFullContainerRequest] =
    useState<PolicyImportFullContainerRequestDTO>(defaultRequest);
  const [totalFilesFound, setTotalFilesFound] = useState(0);
  const [percentUploaded, setPercentUploaded] = useState(-1);
  const [filesFoundMessage, setFilesFoundMessage] = useState("");
  const [waiting, setWaiting] = useState(false);

  const { everFetch } = useContext(AuthenticationContext);

  const policyImportService = new PolicyImportService(everFetch!);

  const updateRequest = (field: string, value: string) => {
    const tempContainerRequest: any = { ...fullContainerRequest };

    tempContainerRequest[field] = value;

    setFullContainerRequest(tempContainerRequest);
  };
  const getNumberOfFiles = async () => {
    setWaiting(true);
    let files = 0;
    try {
      files = await policyImportService.getNumberOfFiles(
        fullContainerRequest.ContainerName,
        fullContainerRequest.Folder
      );
      setTotalFilesFound(files);
      setFilesFoundMessage(
        `We have found ${files} files that are ready to be imported.`
      );
    } catch {
      setFilesFoundMessage(
        "There was an error retrieving the number of files. Please check the fields."
      );
      setTotalFilesFound(0);
    }
    setWaiting(false);
  };

  const getPercent = (filesUploaded: number) => {
    const current =
      filesUploaded > totalFilesFound ? totalFilesFound : filesUploaded;
    return Math.floor((current * 100) / totalFilesFound);
  };

  const importFiles = async () => {
    setWaiting(true);
    try {
      let filesUploaded = 0;
      setPercentUploaded(0);
      setFilesFoundMessage(`Your files are being imported... please wait...`);
      while (filesUploaded < totalFilesFound) {
        await policyImportService.importFiles(fullContainerRequest);
        filesUploaded += fullContainerRequest.BatchSize;
        setPercentUploaded(getPercent(filesUploaded));
      }
      setFilesFoundMessage(`Your files were successfully imported.`);
    } catch {
      setTotalFilesFound(0);
      setFilesFoundMessage(
        "There was an error importing the files. Please try again."
      );
    }
    setWaiting(false);
  };

  const containerModified = () => {
    setFilesFoundMessage("");
    setTotalFilesFound(0);
    setPercentUploaded(-1);
  };

  return (
    <PageContainer
      heading="Bulk Policy Import"
      breadcrumbs={breadcrumbs}
      className=""
      rest=""
    >
      <Card>
        <CardContent>
          <Typography variant="body1" gutterBottom>
            With this form we will be able to trigger the bulk import of
            policies using a container in the{" "}
            <a href={getStorageAccountLink()} target="_blank" rel="noreferrer">
              main storage account
            </a>{" "}
            and a folder in it as a source.
          </Typography>
          <Typography variant="body1" gutterBottom>
            Please, enter the container name <b>mandatory</b> and the container
            folder <b>optional</b>. Then click on the <b>check</b> button to
            fetch the <b>.zip</b> files.
          </Typography>
          <Box mt={5} mb={5}>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  variant="outlined"
                  label="Source Container Name"
                  value={fullContainerRequest.ContainerName}
                  onChange={(e) => {
                    updateRequest("ContainerName", e.target.value);
                    containerModified();
                  }}
                  disabled={waiting}
                />
              </Grid>
              <Grid item xs={5}>
                <TextField
                  fullWidth
                  variant="outlined"
                  label="Source Container Folder"
                  value={fullContainerRequest.Folder}
                  onChange={(e) => {
                    updateRequest("Folder", e.target.value);
                    containerModified();
                  }}
                  disabled={waiting}
                />
              </Grid>
              <Grid item xs={1}>
                <Box mt={2}>
                  <EverButton
                    onClick={getNumberOfFiles}
                    variant="contained"
                    color="primary"
                    disabled={waiting}
                  >
                    <Check />
                  </EverButton>
                </Box>
              </Grid>
              <Grid item xs={12}>
                {filesFoundMessage && (
                  <Box m={10} sx={{ textAlign: "center" }}>
                    <Typography variant="h6" color="secondary" gutterBottom>
                      {filesFoundMessage}
                    </Typography>
                  </Box>
                )}
              </Grid>
            </Grid>
          </Box>
          {totalFilesFound > 0 && (
            <>
              <Typography variant="body1" gutterBottom>
                Now let's select the type of product and the importer we will
                use to process the {totalFilesFound} files.
              </Typography>
              <Box mt={5} mb={5}>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <EverSelector
                      options={EversureProducts}
                      value={fullContainerRequest.ProductId}
                      onChange={(value) =>
                        updateRequest("ProductId", value.toString())
                      }
                      disabled={waiting}
                      label="Product"
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      fullWidth
                      variant="outlined"
                      label="Importer"
                      value={fullContainerRequest.Importer}
                      onChange={(e) =>
                        updateRequest("Importer", e.target.value)
                      }
                      disabled={waiting}
                    />
                  </Grid>
                </Grid>
              </Box>
              {fullContainerRequest.ProductId && (
                <>
                  {percentUploaded < 0 && (
                    <Box m={8} sx={{ textAlign: "center" }}>
                      <EverButton
                        onClick={importFiles}
                        variant="contained"
                        color="primary"
                        disabled={waiting}
                      >
                        Import {totalFilesFound} files
                      </EverButton>
                    </Box>
                  )}
                  {percentUploaded >= 0 && (
                    <>
                      <LinearProgress
                        variant="determinate"
                        value={percentUploaded}
                      />
                      {percentUploaded}%
                    </>
                  )}
                </>
              )}
            </>
          )}
        </CardContent>
      </Card>
    </PageContainer>
  );
};

export default BulkPolicyImport;
